<template>
  <ftx-dialog :dialog="dialog" position="bottom">
    <template v-slot:DialogContent>
      <q-card class="mobileCardDialogue">
        <q-card-section class="dialogHeader">
          <q-btn
            class="close absolute-top-right"
            padding="md"
            flat
            v-close-popup
          >
            <SvgIcon icon="close" size="14px" />
          </q-btn>
          <div class="container">
            <div class="row no-wrap">
              <div class="col">
                <h4 class="text-h6 q-ma-none text-black text-center"
                  >Item Instruction</h4
                >
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div class="container">
            <div class="word-break-word q-pb-md">
              {{ properties.dialogText }}
            </div>
          </div>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
function initialState() {
  return {
    loading: false,
    dialog: 'ItemInstructionMobile',
  }
}
export default {
  name: 'ItemInstructionMobile',
  data: function () {
    return initialState()
  },
  components: {},
  computed: {
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
  },
}
</script>

<style lang="scss" scoped></style>
